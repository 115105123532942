import React from 'react'
import Profile from "../../assets/2.jpg";
import './home.css';
import { Link } from 'react-router-dom';
import {FaArrowRight} from "react-icons/fa";

const Home = () => {
  return (
    <section className="home section grid">
      <img src={Profile} alt='' className="home__img" />
      <div className="home_content">
        <div className="home__data">
          <h1 className="home__title">
            <span>I'm Laaroussi Noureddine.</span> Web Developper
          </h1>
          <p className="home__description">
          Full stack developer, able to code in different computer languages.
          A perfect mastery of the design and the realization of a website and a web application.
          </p>

          <Link to='/about' className="button">
              More About Me 
              <span className='button__icon'> 
                <FaArrowRight /> 
              </span>
          </Link>
        </div>
      </div>
      <div className="color__block"></div>
    </section>
  )
}

export default Home